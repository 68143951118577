export const WORDS = [
  'fangi',
  'grýla',
  'loðin',
  'lakur',
  'bátur',
  'lekum',
  'karry',
  'krúna',
  'mjúkt',
  'fliða',
  'dovin',
  'maksa',
  'mamma',
  'elska',
  'mynta',
  'kjóli',
  'longa',
  'dyppa',
  'dugni',
  'glóma',
  'losær',
  'miðil',
  'karma',
  'nøtra',
  'orgul',
  'lampa',
  'dirvi',
  'tekni',
  'gummi',
  'leiga',
  'klæði',
  'íhald',
  'banan',
  'jarða',
  'játta',
  'kinka',
  'nevnd',
  'marra',
  'dunna',
  'gaman',
  'bamsa',
  'mætur',
  'dansa',
  'loppa',
  'latín',
  'gágga',
  'gifta',
  'avrik',
  'glæma',
  'mussa',
  'íløga',
  'ákæra',
  'ljóma',
  'lummi',
  'kaffi',
  'kabal',
  'lorka',
  'halló',
  'lækka',
  'blóma',
  'gjógv',
  'maska',
  'kúrur',
  'kakao',
  'lekum',
  'ævigt',
  'likka',
  'keyla',
  'kaggi',
  'glíma',
  'leður',
  'kulla',
  'amboð',
  'ógitt',
  'dagur',
  'kvíði',
  'kiska',
  'matur',
  'maður',
  'bingo',
  'lyfta',
  'kanna',
  'knørr',
  'áræði',
  'kykna',
  'lutur',
  'klóra',
  'ásýnd',
  'nópur',
  'ganga',
  'kamar',
  'loynd',
  'akfar',
  'húgva',
  'landa',
  'merki',
  'kiosk',
  'miðás',
  'hvítt',
  'bilur',
  'kyssa',
  'dølla',
  'hovin',
  'dukka',
  'felag',
  'babba',
  'kempi',
  'munga',
  'knípa',
  'fátøk',
  'kvota',
  'lissa',
  'mjødn',
  'lodda',
  'moðin',
  'gubbi',
  'kanón',
  'kanin',
  'dansa',
  'kvett',
  'lykil',
  'mikil',
  'kámur',
  'áhald',
  'lækni',
  'mykja',
  'mappa',
  'kurla',
  'nápur',
  'album',
  'lokka',
  'lofna',
  'dímun',
  'arkiv',
  'ketil',
  'króna',
  'ketta',
  'mánin',
  'akker',
  'dópur',
  'døgga',
  'kvørn',
  'labbi',
  'fløti',
  'nissa',
  'njóta',
  'knarr',
  'lóður',
  'hetja',
  'eldur',
  'hátíð',
  'drýpa',
  'girði',
  'íkast',
  'murra',
  'ógilt',
  'aktiv',
  'kanal',
  'kryvl',
  'offur',
  'loysn',
  'linsa',
  'ágerð',
  'hálka',
  'ágóða',
  'nippa',
  'klípa',
  'krúss',
  'klemm',
  'gogga',
  'aksil',
  'opera',
  'jolly',
  'lotur',
  'kundi',
  'apríl',
  'bibba',
  'alarm',
  'noyða',
  'flagg',
  'lógin',
  'njósa',
  'hulda',
  'kálvi',
  'ódjór',
  'deyda',
  'kvæði',
  'hugni',
  'genta',
  'knaki',
  'lókur',
  'húkur',
  'hovna',
  'nøkta',
  'kista',
  'grípa',
  'kánus',
  'brúgv',
  'ámæla',
  'bjørn',
  'kaðal',
  'altíð',
  'alari',
  'kakur',
  'knúsa',
  'biðja',
  'kelda',
  'kasta',
  'bróta',
  'spegl',
  'sigla',
  'kappa',
  'hópur',
  'órudd',
  'gløgg',
  'ostur',
  'dalur',
  'pengi',
  'ungur',
  'hekil',
  'hekta',
  'melón',
  'maðki',
  'vegur',
  'trøll',
  'kalvi',
  'hjóma',
  'bitin',
  'spæla',
  'kappi',
  'heitt',
  'ádráp',
  'nakki',
  'bikar',
  'skýmd',
  'jaorð',
  'móðir',
  'hevja',
  'koyra',
  'starv',
  'kassi',
  'ditta',
  'tjøra',
  'avboð',
  'áhugi',
  'spann',
  'ætlan',
  'heima',
  'mutla',
  'teppi',
  'hugró',
  'látur',
  'pitsa',
  'gamal',
  'belti',
  'ósatt',
  'humor',
  'jakki',
  'hvíld',
  'hepni',
  'umboð',
  'kempa',
  'úlvur',
  'koddi',
  'tekna',
  'hoyrn',
  'tummi',
  'trúgv',
  'kvøða',
  'dálka',
  'skemt',
  'skúli',
  'hylur',
  'tussj',
  'seðil',
  'keypa',
  'panna',
  'komma',
  'spaki',
  'varpa',
  'jólur',
  'rækja',
  'prógv',
  'ivast',
  'vangi',
  'móður',
  'róður',
  'veður',
  'hoyra',
  'armur',
  'hetta',
  'hugsa',
  'honka',
  'jabba',
  'øvugt',
  'gomul',
  'armóð',
  'svein',
  'suppa',
  'kráka',
  'depil',
  'dapur',
  'hyggi',
  'boyli',
  'gnaga',
  'fínur',
  'nætur',
  'gylta',
  'signa',
  'æðrum',
  'flenn',
  'kyrra',
  'sinta',
  'fanum',
  'økjum',
  'ormar',
  'vamla',
  'dríta',
  'vanin',
  'rólið',
  'lutar',
  'trega',
  'gular',
  'fríur',
  'kúlað',
  'posar',
  'líðir',
  'víður',
  'valda',
  'løkum',
  'kjósa',
  'tvørt',
  'áning',
  'elvir',
  'knípu',
  'bjølg',
  'nýtti',
  'kátum',
  'terpa',
  'talið',
  'karrá',
  'knívi',
  'rokið',
  'røkir',
  'lúkan',
  'girdu',
  'vindi',
  'grógv',
  'fræum',
  'lútir',
  'himla',
  'klødd',
  'tamur',
  'skoru',
  'gýtir',
  'taran',
  'gleða',
  'fegna',
  'vakra',
  'sendi',
  'stýra',
  'oysur',
  'kalli',
  'bilti',
  'søtum',
  'ásjón',
  'drumb',
  'lágni',
  'gillu',
  'liðum',
  'óndan',
  'sjóði',
  'stytt',
  'skúmi',
  'óttan',
  'honin',
  'farnu',
  'ódnar',
  'tindi',
  'heims',
  'oysið',
  'kykur',
  'somlu',
  'nærri',
  'gassi',
  'plint',
  'roðan',
  'førum',
  'sutli',
  'brúsi',
  'kvætt',
  'grýni',
  'tjøld',
  'óføra',
  'slabb',
  'okrað',
  'batum',
  'núpin',
  'ansað',
  'fellu',
  'sipar',
  'volið',
  'skráa',
  'sølum',
  'oddan',
  'treku',
  'slóma',
  'sonur',
  'hakki',
  'skrúð',
  'tumma',
  'klikk',
  'noydd',
  'sprek',
  'gjald',
  'kannu',
  'kular',
  'kýttu',
  'pelli',
  'sálmi',
  'skyns',
  'hvøll',
  'umtal',
  'øktir',
  'verum',
  'glógv',
  'gumma',
  'óðali',
  'trátt',
  'illur',
  'iglar',
  'mjáur',
  'devil',
  'eggja',
  'lissu',
  'tánna',
  'virka',
  'verda',
  'løsts',
  'ripað',
  'bulað',
  'svíki',
  'veiti',
  'vørur',
  'sáran',
  'svass',
  'løgir',
  'komur',
  'grótu',
  'álkan',
  'rekja',
  'trekk',
  'nældi',
  'elski',
  'veðri',
  'lúkum',
  'blíða',
  'forta',
  'parti',
  'dømdu',
  'ringt',
  'økini',
  'tekin',
  'ákært',
  'flata',
  'bukta',
  'rúmum',
  'uglum',
  'danan',
  'pøsti',
  'sevju',
  'løvan',
  'kikað',
  'flokk',
  'hokin',
  'alvan',
  'halli',
  'gætur',
  'stuni',
  'ankar',
  'apuna',
  'ragga',
  'durur',
  'borin',
  'skuri',
  'ernum',
  'snill',
  'allar',
  'betur',
  'angra',
  'altso',
  'lokin',
  'ørkin',
  'feril',
  'lønta',
  'sopið',
  'knøum',
  'óeins',
  'brunn',
  'ynski',
  'umbar',
  'kubba',
  'pápan',
  'bamsu',
  'duski',
  'ommur',
  'snilt',
  'keddu',
  'lámum',
  'kvøði',
  'hátti',
  'vørðu',
  'sungu',
  'føðir',
  'nálir',
  'dreya',
  'hóvað',
  'fress',
  'hatti',
  'sanda',
  'bátin',
  'fløtt',
  'havar',
  'evnið',
  'royna',
  'munar',
  'dárum',
  'kraga',
  'foyru',
  'kummu',
  'bragd',
  'eiðir',
  'sárum',
  'løkur',
  'ógerð',
  'matað',
  'fjøra',
  'britu',
  'daman',
  'rappi',
  'vafla',
  'tjald',
  'runið',
  'sterk',
  'glopp',
  'trygg',
  'nøvin',
  'rapar',
  'vegin',
  'blási',
  'múrað',
  'rokum',
  'krúti',
  'sýrað',
  'harma',
  'mínum',
  'rusta',
  'rivið',
  'fangi',
  'grýla',
  'loðin',
  'lakur',
  'bátur',
  'lekum',
  'karry',
  'krúna',
  'mjúkt',
  'fliða',
  'dovin',
  'maksa',
  'mamma',
  'elska',
  'mynta',
  'kjóli',
  'longa',
  'dyppa',
  'dugni',
  'glóma',
  'losær',
  'miðil',
  'karma',
  'nøtra',
  'orgul',
  'lampa',
  'øktan',
  'lætta',
  'hinum',
  'móðum',
  'minnu',
  'brúki',
  'flett',
  'órætt',
  'ofrað',
  'leiva',
  'falda',
  'tomum',
  'modna',
  'ongum',
  'fiksa',
  'grugg',
  'smædd',
  'ávíst',
  'snýtt',
  'blóti',
  'kavar',
  'feigt',
  'siður',
  'listu',
  'fólka',
  'grísl',
  'smell',
  'prýtt',
  'spáka',
  'dagin',
  'navni',
  'lorti',
  'setri',
  'kódna',
  'sleta',
  'prýði',
  'grátt',
  'óbygt',
  'talva',
  'týsku',
  'heilt',
  'rotna',
  'snúsi',
  'áløgd',
  'kutar',
  'ervir',
  'stara',
  'varma',
  'ómaka',
  'klipp',
  'gøddu',
  'skriv',
  'pissa',
  'lúnur',
  'rímað',
  'rímum',
  'ódnir',
  'slúsa',
  'grýtt',
  'røkli',
  'buppu',
  'lembt',
  'fyllu',
  'vølli',
  'stívt',
  'fínir',
  'lekti',
  'kúlan',
  'mánar',
  'skava',
  'tulka',
  'poesi',
  'gráir',
  'fleir',
  'mistu',
  'nipin',
  'kølin',
  'felgu',
  'rivut',
  'gomlu',
  'prýdd',
  'særdu',
  'sjuss',
  'legan',
  'venað',
  'girdi',
  'gests',
  'stýrt',
  'spuni',
  'motta',
  'eydnu',
  'førka',
  'rendu',
  'bakar',
  'lútur',
  'søtur',
  'mesta',
  'spill',
  'stinn',
  'oyðið',
  'síðan',
  'merkt',
  'støðu',
  'tapið',
  'flóta',
  'kráma',
  'klapp',
  'smeit',
  'valsa',
  'selja',
  'klípi',
  'sneis',
  'munir',
  'oddum',
  'brell',
  'kjans',
  'krøkt',
  'vónin',
  'lønir',
  'brast',
  'torað',
  'giftu',
  'hovið',
  'bardi',
  'fløtu',
  'fitta',
  'vórðu',
  'óføtt',
  'mjarr',
  'føtur',
  'ryggi',
  'binda',
  'lásið',
  'neyvu',
  'fínan',
  'okkum',
  'tikin',
  'tyngd',
  'múrur',
  'órini',
  'kavin',
  'lovar',
  'gávum',
  'troki',
  'tráði',
  'skort',
  'salum',
  'fótur',
  'smíði',
  'rakna',
  'tosað',
  'beini',
  'tínir',
  'másan',
  'ísenn',
  'fjalt',
  'fjúsi',
  'ófast',
  'kyndu',
  'roytt',
  'lýrum',
  'bommi',
  'rotar',
  'bjørk',
  'vorði',
  'stási',
  'arkið',
  'borna',
  'lúrur',
  'varna',
  'tyrla',
  'ljóni',
  'runni',
  'línur',
  'birti',
  'beisk',
  'pápar',
  'børan',
  'fanst',
  'grovu',
  'jogga',
  'ytstu',
  'blást',
  'mungi',
  'grund',
  'tuffi',
  'stumt',
  'ómild',
  'kolon',
  'dovnu',
  'lútin',
  'morði',
  'rytan',
  'píkað',
  'makur',
  'kósir',
  'dæmið',
  'vøkru',
  'reiða',
  'liðar',
  'óndur',
  'mutar',
  'ránið',
  'gildi',
  'yndað',
  'livið',
  'snakk',
  'blóði',
  'rómin',
  'gjørt',
  'hurla',
  'pøsar',
  'artin',
  'murur',
  'løður',
  'fórst',
  'flógv',
  'hepnu',
  'sagda',
  'svalu',
  'óbýli',
  'betra',
  'lýstu',
  'morli',
  'sólja',
  'skegg',
  'fylki',
  'slank',
  'fugað',
  'mjúki',
  'djass',
  'barki',
  'bumma',
  'oyður',
  'lærdi',
  'nykur',
  'finni',
  'aldan',
  'lobba',
  'hótti',
  'hóska',
  'feriu',
  'hitta',
  'pílur',
  'svíða',
  'vigið',
  'sælum',
  'kølið',
  'dríti',
  'perlu',
  'frægi',
  'skjal',
  'sníka',
  'sørar',
  'putli',
  'turka',
  'vedda',
  'íðuna',
  'bragð',
  'tætti',
  'noðum',
  'etnar',
  'tíðum',
  'lýtið',
  'garni',
  'skíta',
  'kvíða',
  'dubba',
  'dýnan',
  'fyrru',
  'jassi',
  'velji',
  'kákið',
  'hópar',
  'bitar',
  'puffi',
  'innií',
  'krýnt',
  'blusa',
  'snóri',
  'treiv',
  'benda',
  'aftri',
  'rædda',
  'split',
  'snata',
  'gløða',
  'atlit',
  'ásnum',
  'angin',
  'vitið',
  'grími',
  'kávar',
  'lærir',
  'seldi',
  'hølva',
  'sigga',
  'rubin',
  'urðin',
  'gudum',
  'fongi',
  'vardu',
  'túrar',
  'ókunn',
  'víkir',
  'klókt',
  'tóman',
  'sonin',
  'ørnin',
  'kolan',
  'lekin',
  'teppa',
  'sámdu',
  'ótøtt',
  'yngir',
  'plága',
  'opast',
  'brimi',
  'bukað',
  'pelar',
  'góðum',
  'skála',
  'smæst',
  'oddur',
  'dánan',
  'sviði',
  'snodd',
  'skúra',
  'líran',
  'brokk',
  'traff',
  'gitar',
  'spíra',
  'grønt',
  'beitu',
  'gøtur',
  'sitat',
  'bispi',
  'fyrri',
  'físir',
  'úlvum',
  'rampu',
  'rørum',
  'bróti',
  'ánaðu',
  'dygst',
  'pumpa',
  'sleip',
  'oynna',
  'farra',
  'pella',
  'galli',
  'loypi',
  'prøvi',
  'snart',
  'stapi',
  'nýtta',
  'kvali',
  'lírin',
  'sjakk',
  'følva',
  'harri',
  'altan',
  'lekir',
  'skarn',
  'nøtum',
  'lulla',
  'vinum',
  'smáur',
  'gísla',
  'dyrki',
  'skoyt',
  'slógv',
  'támut',
  'nípir',
  'rændi',
  'fetti',
  'tøssa',
  'fánað',
  'oyðan',
  'músli',
  'málað',
  'fríði',
  'rógvu',
  'mætum',
  'frægt',
  'ridla',
  'gjáar',
  'ólært',
  'lendu',
  'júkið',
  'kemba',
  'valár',
  'aktað',
  'tíndi',
  'glans',
  'mikið',
  'eldri',
  'osaðu',
  'ólagi',
  'kusti',
  'tagna',
  'tvøga',
  'fundi',
  'nytta',
  'talar',
  'kærir',
  'krýna',
  'komið',
  'fevnt',
  'rópar',
  'búgva',
  'manst',
  'ovtók',
  'trekt',
  'tínum',
  'fikan',
  'larvu',
  'spurt',
  'varði',
  'dylja',
  'vøran',
  'stava',
  'pluss',
  'vovið',
  'meðal',
  'eyrut',
  'hvíla',
  'kodda',
  'hakin',
  'gróvu',
  'víktu',
  'vikað',
  'uggan',
  'glæra',
  'kampi',
  'malør',
  'hevði',
  'pilki',
  'nærum',
  'ringu',
  'hvíli',
  'kagin',
  'myndi',
  'venan',
  'óløtt',
  'ógagn',
  'búnan',
  'kjarr',
  'vátur',
  'vinir',  
]
